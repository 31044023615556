.story-preview {
  padding: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 60%;
  margin: 0px auto;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #eeebf3a1
}

.story-part {
  margin-bottom: 20px;
}

.illustration-container {
  margin-top: 10px;
}

.illustration-container img {
  max-width: 100%;
  border: 1px solid #ccc;
}

.save-story-button {
  flex-grow: 1 !important;
  padding: 8px 16px !important;
}

.discard-story-button {
  padding: 8px 16px !important;
  border-color: red !important;
  margin-left: 20px !important;
}

.button-container {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 220px !important;
  margin: 20px auto !important;
}
