.contentArea {
  display: flex;
  max-width: 1200px;
  /* or whatever maximum width you prefer */
  margin: 0 auto;
  /* center the content horizontally */
}

.sidebar {
  flex: 1;
  border-right: 1px solid gray;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  max-height: 80vh;
  /* Add max height for scrolling if stories list is long */
  overflow-y: auto;
  /* Allow scrolling for sidebar */
}

.storyDisplay {
  flex: 3;
  padding: 10px;
  overflow-y: auto;
  /* If the story content is long */
  max-height: 80vh;
  /* Add max height for scrolling */
}

.mainTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 0;
  /* Reduce top margin to conserve space */
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-top: 4px;
  /* Reduce margins to save space */
  margin-bottom: 4px;
}

.illustrationContainer {
  max-width: 100%;
  /* Ensure images don't overflow */
}

.storyImage {
  max-width: 100%;
  /* Make sure images scale down if they are too big */
  height: auto;
}

.storyContent {
  margin-top: 4px;
  /* Adjust margins to save space */
  margin-bottom: 4px;
}

.storyPart {
  margin-bottom: 16px;
  /* Provide a bit more space between parts for readability */
}

.storyItem {
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 6px;
    transition: background-color 0.3s ease; /* Smooth transition for hover and selected states */
}

/* Hover effect for a story in the list */
.storyItem:hover {
    background-color: #7c7c7c96 !important;
    cursor: pointer;
}

.storyItem.selected {
  background-color: #d3d3d3 !important;
  font-weight: bold;
}

.sidebarTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  /* Adjust as needed */
}

.storyItem:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
}
